<template>
  <section class="montserrat-regular m-auto col-9 col-9-s">
    <div class="container heading pt-5 text-center">
      <h2>
        <span style="background-color: var(--secondary-blue)">
          CONFÍAN EN NOSOTROS
        </span>
      </h2>
    </div>
    <div class="mb-5 text-center">
      <img
        src="../assets/alianzas.jpg"
        class="w-100 alliance-img d-none d-lg-inline"
        alt=""
      />
      <img src="../assets/alianzas-mobile.jpg" class="d-lg-none w-100" alt="" />
    </div>
  </section>
</template>

<script>
export default {
  name: "AlliancesPartners",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
