<template>
  <MainBanner class="d-none d-md-flex" :title="mainTitle" />
  <img class="w-100 montserrat-regular d-md-none" :src="images.mainMobile" />
  <SecondSection :information="secondSection" />
  <AlliancesPartners />
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";
import SecondSection from "@/components/SecondSection.vue";
import AlliancesPartners from "@/components/AlliancesPartners.vue";

import mainBannerImage from "@/assets/main-banner-alianzas-01.png";
import bannerImage from "@/assets/second-banner-alianzas-01.png";
import mainMobile from "@/assets/mobile/alianzas-02-m.png";

export default {
  name: "Producers",
  components: {
    MainBanner,
    SecondSection,
    AlliancesPartners,
  },
  data() {
    return {
      images: {
        mainMobile,
      },
      mainTitle: {
        first: "NUESTRAS",
        second: "ALIANZAS",
        img: mainBannerImage,
        bgColor: "var(--dark-blue)",
      },
      secondSection: {
        goto: "productores",
        gotoId: "#vitaeForm",
        title: "BRINDAMOS SOLUCIONES INTEGRALES",
        text: "Estamos asociados estratégicamente con las principales compañías aseguradoras del mercado.",
        bannerTitleOne: "UNIÓN",
        bannerTitleSecond: "Y FUERZA",
        bannerText: `Mas de 15.000 clientes y 30 agencias oficiales cuentan con nuestro servicio. Realizamos la gestion de venta de seguros en concesionarios de vehículos`,
        img: bannerImage,
        bgColor: "var(--secondary-blue)",
      },
    };
  },
};
</script>
